<template>
  <div>
    <b-row class="mb-4">
      <b-col class="settings-title" cols="10">
        {{ $t('views.wallet-refills.add-wallet.select-wallet') }}
      </b-col>
    </b-row>
    <d-table
      identifier="creditsPack"
      sticky-header="540px"
      :tablefields="fields"
      :items="items"
      :total-rows="totalRows"
      :per-page="totalRows"
      :is-busy="isBusy"
      :show-pagination="false"
      :trCursorPointer="true"
      @row-clicked="onRateRowSelect"
      @on:radio-button-rate-check="onRateRowSelect"
    />
  </div>
</template>
<script>
import {getAllWalletRefills} from "@api/doinsport/services/wallet-refills/wallet-refills.api";
import WalletRefill from "@/classes/doinsport/WalletRefill";

export default {
  data: () => ({
    items: [],
    isBusy: false,
    totalRows: 500,
  }),
  props: {
    selectedWalletRefill: {
      type: Object,
      default: () => ({
        values: null,
        selected: false,
      })
    },
  },
  computed: {
    alignCellsToLeft() {
      return this.$store.getters['layout/getInnerWidth'] >= 991 ? 'text-center' : 'text-left';
    },
    alignCellsToLeftWithCustomWidth() {
      return this.$store.getters['layout/getInnerWidth'] >= 991 ? 'text-center w-5' : 'text-left w-5';
    },
    fields() {
      return [
        {
          key: "singleSelectionRate",
          class: this.alignCellsToLeftWithCustomWidth,
          label: "",
          sortable: false
        },
        {
          key: "name",
          label: this.$t('views.wallet-refills.list.name'),
          sortable: true,
        },
        {
          key: "price",
          label: this.$t('views.wallet-refills.list.price-label'),
          sortable: true
        },
        {
          key: "amount",
          label: this.$t('views.wallet-refills.list.amount'),
        },
      ];
    },
  },
  methods: {
    onRowSelect(row) {
      this.selectedWalletRefill.selected = true;
      this.selectedWalletRefill.values = row;
    },
    onRateRowSelect(row) {
      for (const rate of this.items) {
        if (row.id === rate.id) {
          rate.singleSelectionRate = 'active';
          row.singleSelectionRate = 'active';
          this.onRowSelect(row);
        } else {
          rate.singleSelectionRate = false;
        }
      }
    },
    loadWalletRefills() {
      this.items = [];
      this.isBusy = true;

      getAllWalletRefills(500, 1, '')
        .then(response => {
          this.totalRows = response.data['hydra:totalItems'];
          for (const wallet of response.data['hydra:member']) {
            const serializedWallet = new WalletRefill(wallet, {serialize: true});
            serializedWallet.amount += ' ' + this.$currency;

            Object.assign(serializedWallet, {singleSelectionRate: false})

            this.items.push(serializedWallet);
          }
        })
        .finally(() => {
          this.isBusy = false;
        })
      ;
    }
  },
  created() {
    this.loadWalletRefills();
  }
}
</script>
